import {
  AssigneeResponse,
  BidChangeStatusRequest,
  BidChangeStatusResponse,
  BidCreateRequest,
  BidCreateResponse,
  CreateDuplicatesRequest,
  CreateOfferRequest,
  CreateOfferResponse,
  CreateTemplateRequest,
  GeoOfferMatchesResponse,
  GetMyBidsRequest,
  GetMyBidsResponse,
  GetMyOffersBody,
  GetMyOffersParams,
  GetOfferBidsRequest,
  GetOfferBidsResponse,
  GetOfferContactsRequest,
  GetOfferContactsResponse,
  GetOfferGeoStateResponse,
  GetOfferMatchesRequest,
  GetOfferRequest,
  GetOfferResponse,
  GetOfferRouteResponse,
  GetOffersResponse,
  GetPrivateOffersRequest,
  GetPublicOfferRequest,
  GetPublicOffersCompanyRequest,
  GetPublicOffersProfileRequest,
  GetPublicOffersRequest,
  GetTemplatesRequest,
  GetTemplatesResponse,
  MyExportParams,
  Offer,
  ProlongBidRequest,
  ProlongBidResponse,
  SearchParams,
  Template,
  UpdateOfferRequest,
  UpdateOfferResponse,
  UserOrCompanyResponse,
} from '@roolz/types/api/exchange'
import { Profile } from '@roolz/types/api/profiles'
import { AxiosInstance } from 'axios'
import { ApiClients } from '@roolz/api/clients'

export class ExchangeApi {
  exchange: AxiosInstance

  constructor(clients: ApiClients) {
    this.exchange = clients.exchange
  }

  async getAllOffers({ page, records, sort, ...body }: GetPublicOffersRequest | GetPrivateOffersRequest) {
    return this.exchange.post<GetOffersResponse>('/v1/offers-filter', body, {
      params: { page, records, sort },
    })
  }

  async getPublicOffers({ page, records, sort, ...body }: GetPublicOffersRequest) {
    return this.exchange.post<GetOffersResponse>('/v1/public/filter/offers', body, {
      params: { page, records, sort },
    })
  }

  async getCompanyOffers({ companyId, ...params }: GetPublicOffersCompanyRequest) {
    return this.exchange.get(`/v1/public/companies/${companyId}/offers`, { params })
  }

  async getProfileOffers({ profileId, ...params }: GetPublicOffersProfileRequest) {
    return this.exchange.get(`/v1/profile/${profileId}/offers`, { params })
  }

  async createOffer(body: CreateOfferRequest) {
    return this.exchange.post<CreateOfferResponse>('/v1/offers', body)
  }

  async editOffer({ id, ...body }: UpdateOfferRequest) {
    return this.exchange.patch<UpdateOfferResponse>(`/v1/offers/${id}`, body)
  }

  async finish(id: Offer['_id']) {
    return this.exchange.post<Offer>(`/v1/offers/${id}/finish`, null)
  }

  async replay(id: Offer['_id']) {
    return this.exchange.post<Offer>(`/v1/offers/${id}/replay`, null)
  }

  async getMyOffers(params: GetMyOffersParams, data?: GetMyOffersBody) {
    return this.exchange.post<GetOffersResponse>('/v1/my/offers', data || {}, { params })
  }

  async getMyOffersFilterSuggestAssignee(params: SearchParams) {
    return this.exchange.get<AssigneeResponse[]>('/v1/my/offers-filter/suggest/assignee', { params })
  }

  async getMyOffersFilterSuggestBidder(params: SearchParams) {
    return this.exchange.get<UserOrCompanyResponse[]>('/v1/my/offers-filter/suggest/bidder', { params })
  }

  async getMyOffersFilterSuggestWinner(params: SearchParams) {
    return this.exchange.get<UserOrCompanyResponse[]>('/v1/my/offers-filter/suggest/winner', { params })
  }

  async exportMyOffers(params: MyExportParams, data?: GetMyOffersBody) {
    return this.exchange.post<Blob>('/v1/my/export', data, { params, responseType: 'blob' })
  }

  async getTemplates(params: GetTemplatesRequest) {
    return this.exchange.get<GetTemplatesResponse>('/v1/my/templates', { params })
  }

  async createTemplate(body: CreateTemplateRequest) {
    return this.exchange.post<Template>('/v1/templates', body)
  }

  async getOfferContacts({ offer_id, ...params }: GetOfferContactsRequest) {
    return this.exchange.get<GetOfferContactsResponse>(`/v1/offers/${offer_id}/contacts`, { params })
  }

  async deleteTemplate(id: string) {
    return this.exchange.delete(`/v1/templates/${id}`)
  }

  async createDuplicates({ offer: { _id }, ...body }: CreateDuplicatesRequest) {
    return this.exchange.post<Offer[]>(`/v1/offers/${_id}/duplicate`, body)
  }

  async getOffer({ id, ...params }: GetOfferRequest) {
    return this.exchange.get<GetOfferResponse>(`/v1/offers/${id}`, { params })
  }

  async getOfferGeoState({ _id }: Pick<Offer, '_id'>) {
    return this.exchange.get<GetOfferGeoStateResponse>(`/v1/offers/${_id}/geostate`)
  }

  async getOfferPublicGeoState({ _id }: Pick<Offer, '_id'>) {
    return this.exchange.get<GetOfferGeoStateResponse>(`/v1/public/offers/${_id}/geostate`)
  }

  async getOfferRoute({ _id }: Pick<Offer, '_id'>) {
    return this.exchange.get<GetOfferRouteResponse>(`/v1/offers/${_id}/routeway`)
  }

  async getOfferPublicRoute({ _id }: Pick<Offer, '_id'>) {
    return this.exchange.get<GetOfferRouteResponse>(`/v1/public/offers/${_id}/routeway`)
  }

  async getOfferMatches({ id, ...params }: GetOfferMatchesRequest) {
    return this.exchange.get<GeoOfferMatchesResponse>(`/v1/offers/${id}/matches`, { params })
  }

  async getPublicOffer({ id, ...params }: GetPublicOfferRequest, config?: any) {
    return this.exchange.get<GetOfferResponse>(`/v1/public/offers/${id}`, { params, ...config })
  }

  // Bids
  async createBid({ offerId, body }: BidCreateRequest) {
    return this.exchange.post<BidCreateResponse>(`/v1/offers/${offerId}/bid`, body)
  }

  async changeBidStatus({ offerId, bidId, body }: BidChangeStatusRequest) {
    return this.exchange.post<BidChangeStatusResponse>(`/v1/offers/${offerId}/bid/id/${bidId}/status`, body)
  }

  async getMyBids(params: GetMyBidsRequest) {
    return this.exchange.get<GetMyBidsResponse>('/v1/my/bids', { params })
  }

  async getOfferBids({ offerId }: GetOfferBidsRequest) {
    return this.exchange.get<GetOfferBidsResponse>(`/v1/offers/${offerId}/bid/all`)
  }

  async getPublicOfferBids({ offerId }: GetOfferBidsRequest) {
    return this.exchange.get<GetOfferBidsResponse>(`/v1/public/offers/${offerId}/bid/all`)
  }

  async prolongBidLifetime({ offerId, bidId, body }: ProlongBidRequest) {
    return this.exchange.post<ProlongBidResponse>(`/v1/offers/${offerId}/bid/id/${bidId}/prolongate`, body)
  }
  //

  getOffersCountByProfileId(id: Profile['id']) {
    return this.exchange.get<{ count: number }>(`/v1/profile/${id}/offers/count`)
  }
}
