import { useLayoutEffect } from 'react'
import { useParams } from 'react-router'
import { isCargoOffer } from '@roolz/types/api/exchange'
import { fireViewOfferStrange, fireViewOwnOffer } from '@/config/events'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { OverlayModalPage } from '@/global-modals/HOC/OverlayModalPage'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { exchangeService } from '@/store/exchange/exchange.service'
import { partnershipService } from '@/store/partnership/partnership.service'
import { profilesStore } from '@/store/profiles/profiles.store'

const OfferView = OverlayModalPage(({ onClose }) => {
  const { offer_id } = useParams()

  const globalModals = useGlobalModals()

  async function loadIfNeeded(offer_id: string) {
    const highestVisibleOverlay = globalModals.visibleModals
      .filter(modal => modal?.modal?.meta?.type === 'overlay').at(-1)

    if(highestVisibleOverlay?.modal?.id === GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE
      && highestVisibleOverlay?.key === offer_id
    ) {
      return
    }

    const offerId = new Promise((resolve, reject) => {
      if (profilesStore.activeCompanyId) partnershipService.getPartners(profilesStore.activeCompanyId)

      exchangeService.loadOffer({ id: offer_id })
        .then(async offer => {
          const offer_type = isCargoOffer(offer) ? 'cargo' : 'transport'

          const isMyOffer = profilesStore.hasAccessToOffer(offer)

          const linkedOfferId = offer.partners_selection_original_offer_id
            || offer.partners_selection_dependent_offer_id

          isMyOffer
            ? fireViewOwnOffer({ offer_id: offer._id, offer_type })
            : fireViewOfferStrange({ is_spa_app: true, offer_type, offer_id })

          if (linkedOfferId && isMyOffer) await exchangeService.loadOffer({ id: linkedOfferId })

          resolve(offer._id)
        })
        .catch(e => {
          console.error(e)

          reject(e)
        })
    })

    globalModals.open(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE, {
      key: offer_id,
      props: { id: offerId, onClose },
      config: { onClose },
    })
  }

  useLayoutEffect(() => {
    if(offer_id) loadIfNeeded(offer_id)
  }, [offer_id])

  return null
})

export default OfferView
