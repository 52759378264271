import { Message } from '@roolz/types/api/chats'
import { Offer } from '@roolz/types/api/exchange'
import { ContactInfo } from '@roolz/types/api/profiles'
import { evebus } from 'evebus'

type OldOffer = Offer
type NewOffer = Offer

export type Events = {
  'contacts/contactAdded': ContactInfo
  'messages/messageCreated': Message
  'offers/duplicated': NewOffer[]
  'offers/created': Offer
  'offers/updated': [OldOffer, NewOffer],
  'companyAdmin/usersInvited': { companyId: string },
  'billing/transactionCreated': void
  'offers/refetch': NewOffer['_id'][]
}

export const bus = evebus<Events>()
